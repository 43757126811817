export const config = {
    server_url: "http://osmap.org:8002",
    requestTimeout: 1000 * 60,
    timeoutState: "timeout"
}

export const GameStates = {
    NEW: "new",
    WAIT: "wait",
    READY: "ready",
    PLAY: "play",
    END: "end"
}

export const gameMessages = {
    WIN_MESSAGE: "You Win!",
    LOSE_MESSAGE: "You Lose!",
    GAME_END_MESSAGE: "Game End",
    GAME_ON_MESSAGE: "Game On",
    LOADING: "Loading ...",
    WAITING_OPPONENT: "Waiting for opponent ..."
}


