import React, {Component} from "react";
import Game from "../game/game";
import "./../css/main.scss";
import Alert from "./alert";
import "bootstrap/dist/css/bootstrap.css";
import server from "../http/server";
import {gameMessages, config} from "../game_config";

export default class extends Component{
    constructor(props){
        super(props);

        this.state = {showDialog: false, statusMessage: gameMessages.LOADING};

        this.boarRows = boarRows.bind(this);
        this.boardColums = boardColums.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.validateResponse = this.validateResponse.bind(this);
        this.play = this.play.bind(this);
        this.startGame = this.startGame.bind(this);
    }

    onKeyDown(event){
        this.state.game.setDirectionByKeyCode(event.keyCode);
    }

    async play(){
        let notEndGame = this.state.game.nextMoveByDirection();

        if(notEndGame) {
            let data = await server.play(this.state.game.gameId, this.state.game.playerId, notEndGame, this.state.game.speed * 3 );
            if(this.validateResponse(data)) {
                this.state.game.addOponentMove(data.oMove);
                this.setState({game: this.state.game});
                if (data.oMove) {
                    setTimeout(this.play.bind(this), this.state.game.speed);
                } else {
                    this.setState({showDialog: true, message: gameMessages.WIN_MESSAGE, statusMessage: gameMessages.GAME_END_MESSAGE, newGameButtton:true});
                }
            }
        }else{
            await server.play(this.state.game.gameId, this.state.game.playerId, 0, this.state.game.speed * 3);
            this.setState({showDialog: true,  message: gameMessages.LOSE_MESSAGE, statusMessage: gameMessages.GAME_END_MESSAGE, newGameButtton: true});
        }
    }

    validateResponse(data){
        if(data.error){
            if(data.error === "reload"){
                document.location.reload();
            }else {
                let newState = {showDialog: true, message: data.error, newGameButtton: true};
                if (data.status === config.timeoutState) {
                    newState.onResend = this.startGame;
                    newState.newGameButtton = false;
                }
                this.setState(newState);
                return false;
            }
        }
        return true;
    }

    async startGame(){
        let data = await server.start(this.state.game.gameId, this.state.game.playerId);
        if(this.validateResponse(data)) {
            this.setState({statusMessage: gameMessages.GAME_ON_MESSAGE});
            setTimeout(this.play, this.state.game.speed);
        }
    }

    componentDidMount(){
        (
            async ()=>{
                let data = await server.init();
                if(this.validateResponse(data)) {
                    this.setState({
                        game: new Game(data.gameId, data.playerId, data.start, data.oponentStart,
                            data.color, data.direction, data.boardHeight, data.boardWidth, data.speed),
                        statusMessage: gameMessages.WAITING_OPPONENT
                    });
                    document.addEventListener("keydown", this.onKeyDown, false);

                    await this.startGame();
                }
            }
        )();
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.onKeyDown, false);
    }

    render(){
        return (
            <div className="board">
                <div className="status-bar">
                    <p>{this.state.statusMessage}</p>
                </div>
                {this.state.game && <div className={"color-bar" + fielStyle(this.state.game.color)}></div>}
                <table>
                    <tbody>
                    {this.boarRows()}
                    </tbody>
                </table>

                <Alert
                    message={this.state.message}
                    show={this.state.showDialog}
                    onResend={this.state.onResend}
                    onHide={() =>{this.setState({showDialog:false, onResend: null})}}
                    newGameButton={this.state.newGameButtton}
                />
            </div>
        );
    }
}

const fillArray = lenhth =>{
    let arr = new Array(Number(lenhth));
    arr.fill(null);
    return arr;
}

function boarRows (){
    if(this.state.game){

        let arr = fillArray(this.state.game.boardHeight);
        return arr.map((a, index)=>{
            return <tr key={index}>{this.boardColums(index, this.state.game.boardWidth)}</tr>
        });
    }

}

function boardColums (row, colums){
    let arr = fillArray(colums);
    return arr.map((a, index)=>{
        let pos = row * colums + (index + 1);
        return <td className={"game-box" + fielStyle(this.state.game.checkField(pos))} key={pos}></td>
    });
}

const fielStyle = owner =>{
    switch (owner){
        case 1:
            return " game-box-green";
        case 2:
            return " game-box-red";
        default:
            return "";
    }
}