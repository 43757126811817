import React, {Component} from "react";
import {Modal, Button} from 'react-bootstrap';

export default class extends Component{

    render () {
        return (
            <Modal
                show={this.props.show}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={this.props.onHide}
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Game Over
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.message}
                </Modal.Body>
                <Modal.Footer>
                    {this.props.onResend && <Button onClick={()=>{
                        this.props.onResend();
                        this.props.onHide();
                    }}>Wait</Button>}
                    {this.props.newGameButton && <Button onClick={()=>{
                        document.location.reload();
                    }}>New Game</Button>}
                    <Button onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}