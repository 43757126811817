
export default class{
    constructor(gameId, playerId, start, oponent, color, direction, boardHeight, boardWidth, speed){
        this.gameId = gameId;
        this.playerId = playerId;
        this.me = [start];
        this.opponent = [oponent];
        this.direction = direction;
        this.color = color;
        this.boardHeight = boardHeight;
        this.boardWidth = boardWidth;
        this.speed = speed;

        this.nextMoveByDirection = this.nextMoveByDirection.bind(this);
    }

    next(myMove, opponentMove){

    }

    nextMoveByDirection(){
        let current = this.me[this.me.length -1];
        let {x, y} = this.coords(current);
        let next;
        switch (this.direction){
            case "L":
                next = current - 1;
                if(x - 1 > 0 && !this.checkField(next)){
                    this.me.push(next);
                }else{
                    next = null;
                }
                break;
            case "R":
                next = current + 1;
                if(x + 1 <= this.boardWidth && !this.checkField(next)){
                    this.me.push(next);
                }else{
                    next = null;
                }
                break;
            case "U":
                next = current - this.boardWidth;
                if(y - 1 > 0 && !this.checkField(next)){
                    this.me.push(next);
                }else{
                    next = null;
                }
                break;
            case "D":
                next = current + this.boardWidth;
                if(y + 1 <= this.boardHeight && !this.checkField(next)){
                    this.me.push(next);
                }else{
                    next = null;
                }
                break;
            default:
                break;
        }
        return next;
    }

    checkField(field){
        if(this.me.includes(field)){
            return this.color;
        }else if(this.opponent.includes(field)){
            return (this.color === 2) ? 1 : 2;
        }
        return 0;
    }

    setDirectionByKeyCode(keyCode){
        switch (keyCode){
            case 37:
                this.direction = "L";
                break;
            case 38:
                this.direction = "U";
                break;
            case 39:
                this.direction = "R";
                break;
            case 40:
                this.direction = "D";
                break;
            default:
                break;
        }
    }

    addOponentMove(move){
        this.opponent.push(move);
    }

    coords(pos){
        let x = pos % this.boardWidth;
        return {x: (x === 0) ? this.boardWidth : x , y: Math.ceil(pos / this.boardWidth)};
    }
}
