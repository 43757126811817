import axios from "axios";
import {config} from "../game_config";

const timeout_format = /timeout of \d{1,}ms exceeded/

export default {
    init: async function (){
        let data;
        try {
            data = (await axios.get(config.server_url + "/game")).data;
        }catch (e){
            data = {error: "Failed to init game. [" + e.message +"]"};
        }
        return data;
    },
    start: async function (gameId, playerId){
        let data;
        try {
            data = (await axios({
                method: "get",
                url: config.server_url + "/game/" + gameId + "/" + playerId,
                timeout: config.requestTimeout
            })).data;
        }catch (e){
            data = {error: "Failed to find opponent. [" + e.message +"]"};
            if( e.message.match(timeout_format)){
                data.status = config.timeoutState;
            }
        }

        return data;
    },
    play: async function (gameId, playerId, move, timeout){
        let data;
        try {
            data = (await axios({
                method: "post",
                url: config.server_url + "/game/" + gameId + "/" + playerId,
                timeout: timeout,
                data: {
                    move: move,
                }
            })).data;
        }catch (e){
            data = {error: "Your opponent quited the game. [" + e.message +"]"};
        }
       return data;
    },
}
